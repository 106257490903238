import React, {useState, useEffect, useCallback} from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import api from '../api/api.js'

import './widget.scss';

const daysSelectorOptions = [
    {key: '1 minute', label: '1 minute'},
    {key: '5 minutes', label: '5 minutes'},
    {key: '30 minutes', label: '30 minutes'},
    {key: 'hourly', label: 'Hourly'},
    {key: '5', label: '5 days'},
    {key: '10', label: '10 days'},
    {key: '30', label: '30 days'},
    {key: '60', label: '60 days'},
    {key: '90', label: '90 days'},
];

const LiveChartWidget = ({currency}) => {
    const [chartDays, setChartDays] = useState('10');
    const [errorMessage, setErrorMessage] = useState(null);
    const [data, setData] = useState([]);

    const refreshData = useCallback((days) => {
        console.log('refreshData');
        api.getLiveChart(currency.currency1, currency.currency2, days, 'UTC').then(serverData => {
            setData(serverData.data.chart);

        }).catch(e => {
            let message = 'An unknown error has occured';
            if(e.data && e.data.message) {
                message = e.data.message;
            }

            setErrorMessage(message);

            setTimeout(() => {
                setErrorMessage(null);

                refreshData(days);
            }, 15000);
        });
    },[currency]);

    useEffect(() => {
        refreshData(chartDays);
        
    }, [refreshData, chartDays, currency]);

    const options = {
        rangeSelector : {
            enabled: false
        },
        navigator : {
            enabled: false
        },
        scrollbar : {
            enabled: false  
        },
        credits : {
            enabled: false  
        },
        title : {
            text : ''
        },
        yAxis: [{ // left y axis
            title: {
                text: ''
            },
            opposite: true,
            labels: {
                align: 'left',
                x: 5,
                formatter: function() {
                    return this.value.toFixed(4);
                }
            },
            showFirstLabel: false
        }],
        series : [{
            type : 'candlestick',
            name : `${currency.currency1}/${currency.currency2}`,
            data
        }]
    };

    const handleDaysChange = (event) => {
        const key = event.target.value;
        setChartDays(key);
    };

    const renderDaysSelector = () => {
        return (
            <select onChange={handleDaysChange}>
                {daysSelectorOptions.map(option => 
                    <option key={option.key} value={option.key}>{option.label}</option>
                )}
            </select>
        );
    };

    return (
        <div className="LiveRateWidget widget">
            <div className="widget__title">
                {currency.currency1}/{currency.currency2} Chart

                <div className="daysSelector">
                    {renderDaysSelector()}
                </div>
            </div>
            <div className="widget__body">
                {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
                )}

                <div className="widgetChart">
                    <HighchartsReact
                        containerProps={{ style: { height: "350px" } }}
                        constructorType = { 'stockChart' }
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
};

export default LiveChartWidget;