const apiURL = 'https://api.klarityfx.com';

exports.getLiveData = () => {
    return new Promise((resolve, reject) => {
        return fetch(`${apiURL}/getLiveRate`, {
            method: 'POST',
            headers: {
            'content-type': 'application/json',
            },
        }).then(r => r.json()).then(response => {
            if (!response.success) {
                reject(response);
            } else {
                resolve(response);
            }
        }).catch(e => {
            console.log(e);
            reject({
                success: false,
                data: {
                    message: 'Could not contact the widget\'s server'
                }
            });
        });
    });
    
};

exports.getLiveSpotRate = () => {
    return new Promise((resolve, reject) => {
        return fetch(`${apiURL}/getLiveSpotRate`, {
            method: 'POST',
            headers: {
            'content-type': 'application/json',
            },
        }).then(r => r.json()).then(response => {
            if (!response.success) {
                reject(response);
            } else {
                resolve(response);
            }
        }).catch(e => {
            console.log(e);
            reject({
                success: false,
                data: {
                    message: 'Could not contact the widget\'s server'
                }
            });
        });
    });
    
};

exports.getLiveChart = (currency1, currency2, days, timezone) => {
    return new Promise((resolve, reject) => {
        return fetch(`${apiURL}/getDataChart`, {
            method: 'POST',
            body: JSON.stringify({
                currency1,
                currency2,
                days,
                timezone
            }),
            headers: {
                'content-type': 'application/json',
            },
        }).then(r => r.json()).then(response => {
            if (!response.success) {
                reject(response);
            } else {
                resolve(response);
            }
        }).catch(e => {
            console.log(e);
            reject({
                success: false,
                data: {
                    message: 'Could not contact the widget\'s server'
                }
            });
        });
    });
    
};