import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "core-js";
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';

import LiveRateWidget from './widgets/LiveRateWidget';
import LiveChartWidget from './widgets/LiveChartWidget';
import TickerWidget from './widgets/TickerWidget';

function App() {
  const [currency, setCurrency] = useState({
    currency1: 'USD',
    currency2: 'CAD'
  });

  const onSelectCurrency = (currency1, currency2) => {
    setCurrency({
      currency1, currency2
    });
  };

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/ticker">
            <TickerWidget/>
          </Route>
          <Route path="/">
            <div className="row">
              <div className="col-md-7">
                <LiveRateWidget onSelectCurrency={onSelectCurrency}/>
              </div>

              <div className="col-md-5">
                <LiveChartWidget currency={currency}/>
              </div>
            </div>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
