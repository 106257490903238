import React, {useState, useEffect, useCallback, useRef} from 'react';
import BaseTable, { Column } from 'react-base-table';
import DraggableTable from '../components/DraggableTable';
import moment from 'moment';

import api from '../api/api.js'

import 'react-base-table/styles.css'
import './widget.scss';

const LiveRateWidget = ({onSelectCurrency}) => {
    const [width, setWidth] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [data, setData] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(null);

    const bodyRef = useRef(null);

    useEffect(() => {
        if (bodyRef.current) {
            setWidth(bodyRef.current.clientWidth);
        }
    }, [bodyRef]);

    /**
     * finds the corect cell color based on current and previous values
     * @returns {string}
     **/
    const findCellColor = (currency, key, _data, _previousData) => {
        const currentCurrencyRow = _data.find(d => d.currency === currency);
        const previousCurrencyRow = _previousData.find(d => d.currency === currency);

        if (currentCurrencyRow && previousCurrencyRow) {
            const currentValue = currentCurrencyRow[key];
            const previousValue = previousCurrencyRow[key];

            if (currentValue > previousValue) {
                return 'green';
            }else if (currentValue < previousValue) {
                return 'red';
            }
        }

        return 'default';
    };

    const refreshData = useCallback((existingData) => {
        api.getLiveData().then(serverData => {
            let currenciesSort = [];
            if (localStorage) {
                const currencies = localStorage.getItem('currencies');
                if (currencies) {
                    currenciesSort = currencies.split(',');
                }
            }

            let newData = serverData.data.rows.map(r => {
                r.id = r.currency;

                for(let key in r) {
                    if (key !== 'currency') {
                        const color = findCellColor(r.currency, key, existingData, serverData.data.rows);
                        r[`${key}_html`] = (
                            <div className={color}>{r[key]}</div>
                        );
                    } else {
                        r['currency_html'] = (<div className="currencyCell">{r[key]}</div>);
                    }
                }

                return r;
            });

            if (currenciesSort.length) {
                newData = newData.sort((a,b) => {
                    const aIndex = currenciesSort.findIndex(c => c === a.currency);
                    const bIndex = currenciesSort.findIndex(c => c ===b.currency);

                    return aIndex - bIndex;
                });
            }
            
            setData(newData);
            setLastUpdate(moment().format('DD/MM/YYYY HH:mm:ss'));

            setTimeout(() => {
                refreshData(serverData.data.rows);
            }, 5000);
        }).catch(e => {
            let message = 'An unknown error has occured';
            if(e.data && e.data.message) {
                message = e.data.message;
            }

            setErrorMessage(message);

            setTimeout(() => {
            setErrorMessage(null);

            refreshData([]);
            }, 15000);
        });
    }, []);

    useEffect(() => {
        //only running if data is empty
        if (!data.length) {
            refreshData(data);
        }
    }, [refreshData, data]);

    const handleSortEnd = (_data) => {
        const currenciesList = _data.reduce((acc, val) => {
            acc.push(val.currency);
            return acc;
        },[]);
        if (localStorage) {
            localStorage.setItem('currencies', currenciesList.join());
        }

        setData(_data);
    };

    const handleRowClick = (rowData) => {
        const currencies = rowData.currency.split('/');
        onSelectCurrency(currencies[0],currencies[1]);
    };

    const columns = [
        {
            key: 'empty',
            dataKey: 'currency_html',
            title: ''
        },
        {
            key: 'bid',
            dataKey: 'bid_html',
            title: 'Bid'
        },
        {
            key: 'ask',
            dataKey: 'ask_html',
            title: 'Ask'
        },
        {
            key: 'high',
            dataKey: 'high_html',
            title: 'High'
        },
        {
            key: 'low',
            dataKey: 'low_html',
            title: 'Low'
        },
        {
            key: 'percent',
            dataKey: 'change_html',
            title: '%'
        }
    ];

    return (
        <div className="LiveRateWidget widget widget-with-footer">
            <div className="widget__title">
                Live FX Prices
            </div>
            <div className="widget__body" ref={bodyRef}>
                {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
                )}

                {
                data.length > 0 ? (
                    <DraggableTable data={data} width={width} height={325} rowHeight={28} handleSortEnd={handleSortEnd} onRowClick={handleRowClick}>
                        {columns.map(column => (
                        <Column key={column.key} {...column} width={(width / 6)} />
                        ))}
                    </DraggableTable>
                    )
                             :
                    (<div>Loading...</div>)
                }
            </div>
            <div className="footer">
                {lastUpdate && (
                    <span>Latest update: {lastUpdate}</span>
                )}
            </div>
        </div>
    );
};

export default LiveRateWidget;

/* ({ /*
                    <table className="table table-striped">
                    <thead>
                        <tr>
                        <th></th>
                        <th>Bid</th>
                        <th>Ask</th>
                        <th>High</th>
                        <th>Low</th>
                        <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        data.map(d => {
                            return (
                            <tr key={d.currency} onClick={() => {
                                const currencies = d.currency.split('/');
                                onSelectCurrency(currencies[0],currencies[1]);
                            }}>
                                <td>{d.currency}</td>
                                <td className={`cell-${findCellColor(d.currency, 'bid')}`}>{d.bid}</td>
                                <td className={`cell-${findCellColor(d.currency, 'ask')}`}>{d.ask}</td>
                                <td className={`cell-${findCellColor(d.currency, 'high')}`}>{d.high}</td>
                                <td className={`cell-${findCellColor(d.currency, 'low')}`}>{d.low}</td>
                                <td className={`cell-${findCellColor(d.currency, 'change')}`}>{d['change']}</td>
                            </tr>
                            )
                        })
                        }
                    </tbody>
                    </table>) */