import React, {useState, useEffect, useCallback, useRef} from 'react';

import useInterval from 'use-interval';
import api from '../api/api.js'

import './widget.scss';

const TickerWidget = () => {
    const [previousData, setPreviousData] = useState([]);
    const [data, setData] = useState([]);
    const [leftAnimation, setLeftAnimation] = useState(0);
    const [onHover, setOnHover] = useState(false);

    const ref = useRef(null);

    const refreshData = useCallback((existingData) => {
        api.getLiveSpotRate().then(serverData => {
            setPreviousData(existingData);
            setData(serverData.data.rows);

            setTimeout(() => {
                refreshData(serverData.data.rows);
            }, 5000);
        }).catch(e => {
            setTimeout(() => {
                refreshData([]);
            }, 15000);
        });
    }, []);

    useEffect(() => {
        //only running if data is empty
        if (!data.length) {
            refreshData(data);
        }
    }, [refreshData, data]);

    useInterval(() => {
        setLeftAnimation(val => {
            let newVal = val - (onHover ? 1 : 2);

            const tickerWidth = ref.current ? ref.current.offsetWidth : window.innerWidth;

            if (Math.abs(newVal) > tickerWidth) {
                newVal = 0;
            }

            return newVal;
        });
    }, 40);

    const renderTicker = useCallback((currency, value, previousValue) => {
        let color = 'white';
        if (previousValue && value > previousValue) {
            color = 'green';
        }else if (previousValue && value < previousValue) {
            color = 'red';
        }

        return (<div className="Ticker">{currency}: <span className="Ticker__value" style={{color}}>{value}</span></div>);
    },[]);

    return (
        <div className="TickerWidget" onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
            <div className="TickerWidget__row" ref={ref} style={{left: leftAnimation}}>
                {data.map((row) => {
                    const previousValue = previousData.find(previousRow => previousRow.currency === row.currency);
                    return renderTicker(row.currency, row.spot, previousValue ? previousValue.spot : null);
                })}
            </div>
            <div className="TickerWidget__row duplicate" style={{left: leftAnimation}}>
                {data.map((row) => {
                    const previousValue = previousData.find(previousRow => previousRow.currency === row.currency);
                    return renderTicker(row.currency, row.spot, previousValue ? previousValue.spot : null);
                })}
            </div>
        </div>
    );
};
export default TickerWidget;