import React from 'react';
import BaseTable, { callOrReturn } from 'react-base-table';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'

const DraggableContainer = sortableContainer(({ children }) => children)
const DraggableElement = sortableElement(({ children }) => children)
const DraggableHandle = sortableHandle(({ children }) => children)

const Row = ({ key, index, children, ...rest }) => (
  <DraggableElement key={key} index={index}>
    <div {...rest}>
      <DraggableHandle>
        <div className="handle"></div>
      </DraggableHandle>
      {children}
    </div>
  </DraggableElement>
)

class DraggableTable extends React.PureComponent {
  state = {
    activeRowIndex: 0
  }

  table = React.createRef()

  getContainer = () => {
    return this.table.current.getDOMNode().querySelector('.BaseTable__body')
  }

  getHelperContainer = () => {
    return this.table.current.getDOMNode().querySelector('.BaseTable__table')
  }

  rowProps = ({columns, rowData, rowIndex}) => {
    // don't forget to passing the incoming rowProps
    const extraProps = callOrReturn(this.props.rowProps);

    return {
      ...extraProps,
      tagName: Row,
      index: rowIndex,
      onClick: () => {
        this.setState({
          'activeRowIndex': rowIndex
        });

        if (this.props.onRowClick) {
          this.props.onRowClick(rowData);
        }
      }
    }
  }

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const data = [...this.props.data];
    const [removed] = data.splice(oldIndex, 1);
    data.splice(newIndex, 0, removed);

    if(this.props.handleSortEnd) {
        this.props.handleSortEnd(data);
    }
  }

  render() {
    return (
      <DraggableContainer
        useDragHandle
        getContainer={this.getContainer}
        helperContainer={this.getHelperContainer}
        onSortEnd={this.handleSortEnd}
      >
        <BaseTable
          {...this.props}
          ref={this.table}
          data={this.props.data}
          fixed={false}
          rowProps={this.rowProps}
          rowClassName={({columns, rowData, rowIndex}) => {
            return this.state.activeRowIndex === rowIndex ? 'active' : null;
          }}
        />
      </DraggableContainer>
    )
  }
}

export default DraggableTable;